import React from 'react';

import * as styled from './500.styled';
import { ErrorWrapper } from './ErrorWrapper';

export const Content = () => (
  <ErrorWrapper title="Something went wrong">
    <styled.Text style={{ marginBottom: '25px' }}>
      While we check thingsbon our end, refresh the page and{' '}
      <styled.BlueText to="/support">contact support</styled.BlueText> if the
      problem persists.
      <br />
      When contacting support, provide them with this code:
    </styled.Text>
    <styled.CodeBox>
      <styled.Code>9ee87cj008dee035003fff4hdk3000wqf334lj47jh8</styled.Code>
    </styled.CodeBox>
    <styled.Text style={{ marginTop: '25px' }}>
      Our <styled.BlueText to="/support">status page</styled.BlueText> may also
      provide additional details.
    </styled.Text>
  </ErrorWrapper>
);
