import styled from 'styled-components';
import { Link } from 'gatsby';
import * as text from '@/components/general/TextStyles';

export const Text = styled.p`
  ${text.FS22}
`;

export const BlueText = styled(Link)`
  color: ${(props) => props.theme.colors.blue}!important;

  &:hover {
    text-decoration: underline;
  }
`;

export const CodeBox = styled.div`
  padding: 10px 15px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.lightGray2};
  margin: 0 auto;
  max-width: 433px;
`;

export const Code = styled.span`
  ${text.FS18}word-wrap: break-word;
`;
